@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.wrapper {
  padding-left: 26px;
  padding-top: 8px;
  position: relative;
  overflow: hidden;

  @include respond-to(sm) {
    display: none;
  }

  &.opened {
    @include respond-to(sm) {
      display: block;
    }
  }

  & > li > ul {
    padding-top: 1px;
    margin-top: 12px;
  }
}

.list {
  &Item {
    margin-bottom: 8px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
      position: initial;

      &:before {
        content: none;
      }
    }

    &:before {
      content:"";
      position:absolute;
      width: 14px;
      height: 30px;
      left: -14px;
      top: -17px;
      border-radius: 0 0 0 8px;
      padding: 0 0 1px 1px;
      background: linear-gradient(180deg, #5880AE 10.35%, #7D7BEF 46.3%, #DD94FF 77.35%, #EA77AE 104.58%);
      -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
      z-index: 5;
    }
  }

  &Text {
    font-family: $manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding: 0 6px;

    @include respond-to(1399px) {
      line-height: 19px;
    }
    @include respond-to(md) {
      font-size: 10px;
    }
    @include respond-to(sm) {
      font-size: 16px;
      line-height: 22px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.branch {
  position: absolute;
  width: 14px;
  top: -8px;
  left: 12px;
  border-radius: 0 0 0 8px;
  padding: 0 0 1px 1px;
  background: linear-gradient(180deg, #5880AE 10.35%, #7D7BEF 46.3%, #DD94FF 77.35%, #EA77AE 104.58%);
  -webkit-mask:
  linear-gradient(#fff 0 0) content-box,
  linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 10;
}
