@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.wrapper {
  .toggle {
    display: none;

    &.opened {
      display: block;
    }
  }

  ul {
    padding-left: 18px;
  }

  button {
    color: $main-text;
    font-family: $manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: $ls-first;
    padding: 13px 18px 13px 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: none;
    cursor: pointer;

    &.opened {
      .icon {
        transform: rotate(90deg);
      }
    }

    .icon {
      font-size: 22px;
    }
  }
}
