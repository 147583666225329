@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.wrapper {
  @include respond-to(sm) {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  figure {
    width: 158px;
    height: 158px;
    margin-bottom: 24px;

    @include respond-to(md) {
      width: 130px;
      height: 130px;
    }
    @include respond-to(sm) {
      width: 115px;
      height: 115px;
    }

    img {
      width: 100%;
    }
  }

  p {
    color: $main-text;
    font-family: $manrope;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: $ls-first;
    margin-bottom: 6px;

    img {
      &:first-of-type {
        @include respond-to(sm) {
          display: none;
        }
      }
      &:last-of-type {
        display: none;

        @include respond-to(sm) {
          display: block;
        }
      }
    }
  }

  span {
    color: $comment;
    font-family: $manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: $ls-second;
    margin-bottom: 12px;
    display: block;
  }

  ul {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    li {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }

      a {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 12px;

        @include respond-to(sm) {
          width: 24px;
          height: 24px;
        }

        &.twitter {
          background: #55ACE3;
        }
        &.linkedin {
          background: #2878B7;
        }
        &.facebook {
          background: #4267b2;
        }
        &.telegram {
          background: #25a2e0;
        }
      }
    }
  }
}

.icon {
  color: $white;
  font-size: 7px;
}
