$icomoon-font-family: "yourjustice" !default;
$icomoon-font-path: "/assets/fonts/yourjustice/yourjustice" !default;

$if-chat: "\e920";
$if-arrow-circle-up: "\e91f";
$if-github: "\e91e";
$if-error: "\e91d";
$if-chevron-left: "\e91b";
$if-chevron-bottom: "\e91c";
$if-corner-top: "\e919";
$if-corner-bottom: "\e91a";
$if-external-link: "\e917";
$if-search: "\e918";
$if-discord: "\e916";
$if-profile: "\e912";
$if-phone: "\e913";
$if-message: "\e914";
$if-send: "\e915";
$if-chevron-right: "\e90e";
$if-instagram: "\e90b";
$if-blockquote-check-sm: "\e911";
$if-youtube: "\e901";
$if-wallet: "\e902";
$if-twitter: "\e903";
$if-telegram: "\e904";
$if-tag: "\e905";
$if-quote-right: "\e906";
$if-quote-left: "\e907";
$if-medium: "\e908";
$if-linkedin: "\e909";
$if-language-arrow: "\e90a";
$if-form-arrow: "\e90c";
$if-facebook: "\e90d";
$if-blockquote-check: "\e90f";
$if-bag: "\e910";
$if-schedule-arrow: "\e900";
